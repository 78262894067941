import { ResourcePathEnum } from '../../types/api'

import { CreateView } from './Create'

import { EditView } from './Edit'
import { ListView } from './List'
import { ShowView } from './Show'

export const user = {
  name: ResourcePathEnum.users,
  list: ListView,
  edit: EditView,
  show: ShowView,
  create: CreateView,
}
