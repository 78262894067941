import {
  Alert,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Spin,
  Typography,
} from '@pankod/refine'
import type { AxiosError, AxiosResponse } from 'axios'
import axios from 'axios'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
} from 'react-query'
import { useEnv } from 'src/adapters/Env'
import { useParsedLocation } from 'src/libs/useParsedLocation'
import { ResourcePathEnum } from 'src/types/api'

import logoImg from '../UI/theme/logo.svg'
import './index.less'

const { Title } = Typography

const queryClient = new QueryClient()

export function SignupPage() {
  const { t } = useTranslation()
  const { query } = useParsedLocation<{ token: string }>()

  return (
    <QueryClientProvider client={queryClient}>
      <section className="AteLoginPage">
        <img src={logoImg} className="AteLoginPageLogo" />

        <Card className="AteSignupCard">
          <Title level={4}>{t('pages.login.signup')}</Title>
          {query.token ? (
            <SignupForm token={query.token} />
          ) : (
            <ErrorDisplay message="errors.tokenMissing" />
          )}
        </Card>
      </section>
    </QueryClientProvider>
  )
}

interface CheckUser {
  id: string
  email: string
  checkToken: 'ok' | boolean
}

interface ActivateUser extends CheckUser {
  password: string
}

interface ActivateResponse {
  apiToken: string
}

interface Props {
  token: string
}

function SignupForm(props: Props) {
  const { token } = props
  const { t } = useTranslation()
  const env = useEnv()

  const axiosInstance = useMemo(
    () => axios.create({ baseURL: env.API_URL }),
    [env.API_URL],
  )

  const {
    data: checkedUser,
    isSuccess,
    isError,
    error,
    isLoading,
  } = useQuery<AxiosResponse<CheckUser>, AxiosError>('checkToken', () =>
    axiosInstance.get<CheckUser>(`/${ResourcePathEnum.users}/token/${token}`),
  )

  const {
    mutate,
    isLoading: activateIsLoading,
    error: activateError,
  } = useMutation<AxiosResponse<ActivateResponse>, AxiosError, ActivateUser>(
    (credentials) => {
      return axiosInstance.post(
        `${ResourcePathEnum.users}/activate`,
        credentials,
      )
    },
  )

  if (isLoading)
    return (
      <div className="AteSignupLoading">
        <Spin />
      </div>
    )

  if (isError) {
    return <ErrorDisplay message={error?.message} />
  }

  if (isSuccess && checkedUser?.data.checkToken !== 'ok') {
    return <ErrorDisplay message="errors.tokenExpired" />
  }

  const onSubmit = async (values: { password: string }) => {
    if (!checkedUser) return

    if (!checkedUser?.data.checkToken) throw new Error('errors.tokenExpired')

    mutate(
      {
        ...checkedUser.data,
        password: values.password,
      },
      {
        onSuccess() {
          const auth = getAuth()
          signInWithEmailAndPassword(
            auth,
            checkedUser.data.email,
            values.password,
          )
        },
      },
    )
  }

  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="password"
        label={t('pages.login.password')}
        rules={[
          {
            min: 6,
            message: t('validation.passwordLength'),
          },
          {
            required: true,
            message: t('validation.required'),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="terms"
        valuePropName="checked"
        rules={[
          {
            validator(_, value) {
              if (value) return Promise.resolve()
              return Promise.reject(new Error(t('validation.acceptAgreement')))
            },
          },
        ]}
      >
        <Checkbox>
          <Trans
            i18nKey="pages.login.acceptTerms"
            components={{
              cgu: (
                <a
                  href="https://www.alltime-eat.com/conditions-generales-dutilisation"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              cgv: (
                <a
                  href="https://www.alltime-eat.com/conditions-generales-de-vente/"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              terms: (
                <a
                  href="https://www.alltime-eat.com/mentions-legales-et-politique-de-confidentialite"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </Checkbox>
      </Form.Item>

      <Form.Item key="submit">
        <Button
          type="primary"
          htmlType="submit"
          loading={activateIsLoading}
          disabled={!checkedUser}
        >
          {t('buttons.save')}
        </Button>
      </Form.Item>
      {activateError && (
        <ErrorDisplay
          message={
            activateError.response?.data?.detail || activateError.message
          }
        />
      )}
    </Form>
  )
}

interface ErrorProps {
  message?: string
}
function ErrorDisplay(props: ErrorProps) {
  const { message } = props
  const { t } = useTranslation()

  return (
    <Alert
      message={t('pages.error.title')}
      description={message && t(message)}
      type="error"
      showIcon
    />
  )
}
