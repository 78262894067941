import { Typography, useTranslate } from '@pankod/refine'
import { config } from 'src/config'

import './Footer.less'

const style = { fontSize: 12, margin: 4 }

export function Footer() {
  const __ = useTranslate()
  return (
    <div className="AteFooter">
      <Typography.Text style={style}>
        <a href="https://www.alltime-eat.com/mentions-legales-et-politique-de-confidentialite">
          {__('footer.terms')}
        </a>
        {' | '}
        <a href="https://www.alltime-eat.com/conditions-generales-dutilisation">
          {__('footer.CGU')}
        </a>
        {' | '}
        <a href="https://www.alltime-eat.com/conditions-generales-de-vente/">
          {__('footer.CGV')}
        </a>
      </Typography.Text>
      <Typography.Text style={style}>
        {config.APP_DISPLAY_NAME} (v{config.APP_VERSION}) — API (v
        {config.API_VERSION})
      </Typography.Text>
    </div>
  )
}
