import { Skeleton, useOne } from '@pankod/refine'

import { TypographyLink } from './TypographyLink'

interface ReferenceFieldProps<T> {
  resourceName: string
  recordItemId: string
  render: (value: T) => any
  suspense?: boolean
  link?: 'show' | 'edit'
}
export function ReferenceField<T>(props: ReferenceFieldProps<T>) {
  const { resourceName, recordItemId, render, suspense } = props
  const { data, isLoading } = useOne<T>({
    resource: resourceName,
    id: recordItemId,
    queryOptions: {
      suspense,
    },
  })

  if (isLoading) return <Skeleton active paragraph={false} />
  return <>{render(data?.data!)}</>
}

export function ReferenceLinkField<T>(props: ReferenceFieldProps<T>) {
  const { resourceName, recordItemId, link = 'show' } = props

  return (
    <TypographyLink to={`/${resourceName}/${link}/${recordItemId}`}>
      <ReferenceField {...props} />
    </TypographyLink>
  )
}
