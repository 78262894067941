import type { DataProvider } from '@pankod/refine'
import { createContext, useCallback, useMemo, useState } from 'react'

import type { HydraId, UpdateParams } from 'src/adapters/DataProvider'
import { toHydraId } from 'src/adapters/DataProvider'
import type { Item, Promotion } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'

export type UpdatedDispenser =
  | {
      type: 'edition'
    }
  | {
      type: 'promotion'
      promotionId: HydraId
    }

export type UpdatedDispensers = Record<HydraId, UpdatedDispenser | undefined>

export type UpdatedDispensersStore = ReturnType<typeof useUpdatesStore>

export const UpdatedDispensersContext = createContext<UpdatedDispensersStore>({
  get() {
    return undefined
  },
  add() {},
  remove() {},
})

export function useWatchDispenserUpdates(dataProvider: DataProvider) {
  const recentUpdates = useUpdatesStore()
  const { add } = recentUpdates

  const wrappedProvider = useMemo(() => {
    return dataProviderProxy(dataProvider, async (type, updateParams) => {
      const { resource, id, variables } = updateParams
      const resourceId = toHydraId(resource, id)

      if (type === 'update' && resource === 'dispenserEdit') {
        return add(resourceId)
      }

      if (type === 'update' && resource === 'positions') {
        return add(resourceId)
      }

      if (resource === ResourcePathEnum.items) {
        return add((variables as Item).dispenser)
      }

      if (resource === ResourcePathEnum.promotions && type === 'create') {
        const itemResult: { data: Item } = await dataProvider.getOne<Item>({
          resource: ResourcePathEnum.items,
          id: (variables as Promotion).item,
        })
        return add(itemResult.data.dispenser, {
          type: 'promotion',
          promotionId: resourceId,
        })
      }
    })
  }, [dataProvider, add])

  return [recentUpdates, wrappedProvider] as const
}

function dataProviderProxy(
  dataProvider: DataProvider,
  onWrite: (type: 'update' | 'create', params: UpdateParams) => void,
) {
  return new Proxy(dataProvider, {
    get(_, methodName: keyof DataProvider) {
      if (typeof methodName !== 'string') return dataProvider[methodName]
      return async function wrappedMethod(params: any) {
        if (!params) return (dataProvider[methodName] as any)(params)

        const result = await (dataProvider[methodName] as any)(params)
        if (methodName === 'update' || methodName === 'create') {
          onWrite(methodName, { ...params, id: result.data.id })
        }
        return result
      }
    },
  })
}

function useUpdatesStore() {
  const [state, setState] = useState<UpdatedDispensers>({})

  const get = useCallback(
    (resourceId: HydraId) => {
      return state[resourceId]
    },
    [state],
  )

  const add = useCallback(
    (resourceId: HydraId, payload: UpdatedDispenser = { type: 'edition' }) => {
      return setState((prev) => {
        return { ...prev, [resourceId]: payload }
      })
    },
    [],
  )

  const remove = useCallback((resourceId: HydraId) => {
    return setState((prev) => {
      return { ...prev, [resourceId]: undefined }
    })
  }, [])

  return useMemo(() => {
    return {
      get,
      add,
      remove,
    }
  }, [get, add, remove])
}
