import env from '@beam-australia/react-env'
import { createContext, useContext } from 'react'

export type IEnv = ReturnType<typeof Env>

export function Env() {
  function resolve(
    name: string,
    getDefaultValue: () => string = () => isMissing(name),
  ): string {
    return env(name) ?? getDefaultValue()
  }

  const APP_ENV = validateAppEnv(resolve('APP_ENV', () => 'production'))

  return {
    APP_ENV: APP_ENV,
    IS_DEV: APP_ENV === 'development',

    BASE_URL: formatUrl(resolve('BASE_URL')),
    API_URL: formatUrl(resolve('API_URL')),
    MINIO_URL: formatUrl(resolve('MINIO_URL')),

    SENTRY_DSN: resolve('SENTRY_DSN'),

    FIREBASE_API_KEY: resolve('FIREBASE_API_KEY'),
    FIREBASE_AUTH_DOMAIN: resolve('FIREBASE_AUTH_DOMAIN'),
    FIREBASE_PROJECT_ID: resolve('FIREBASE_PROJECT_ID'),
    FIREBASE_STORAGE_BUCKET: resolve('FIREBASE_STORAGE_BUCKET'),
    FIREBASE_MESSAGING_SENDER_ID: resolve('FIREBASE_MESSAGING_SENDER_ID'),
    FIREBASE_APP_ID: resolve('FIREBASE_APP_ID'),
    FIREBASE_MEASUREMENT_ID: resolve('FIREBASE_MEASUREMENT_ID'),
  } as const
}

export const EnvContext = createContext<IEnv | undefined>(undefined)

export function useEnv(): IEnv {
  const resolved = useContext(EnvContext)

  if (!resolved) {
    throw new Error('Please provide an env object')
  }

  return resolved
}

function isMissing(name: string): never {
  throw new Error(`Missing env ${name}`)
}

function formatUrl(url: string) {
  return url.replace(/(\/)+$/, '') // remove trailing slash
}

function validateAppEnv(appEnv: string) {
  const validValues = ['development', 'production', 'integ']
  if (!validValues.includes(appEnv)) {
    throw new Error(
      `Invalid value for APP_ENV: ${JSON.stringify(
        appEnv,
      )}. Valid values are: ${validValues.join(', ')}`,
    )
  }

  return appEnv as 'development' | 'production' | 'integ'
}
