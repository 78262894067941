import {
  AntdLayout,
  useLogout,
  useMenu,
  Menu as RefineMenu,
  Icons,
  useTranslate,
  useGetIdentity,
  useMany,
  Spin,
} from '@pankod/refine'
import classnames from 'classnames'
import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { cleanHydraId } from 'src/adapters/DataProvider'
import type { UserIdentity } from 'src/Auth'
import { ResourcePathEnum } from 'src/types/api'
import type {
  ExtendedDispenser,
  ExtendedUser,
} from 'src/types/api/extendedTypes'

import './Menu.less'
import { Title } from './Title'

const menuItems = [
  { name: '', Icon: Icons.DashboardOutlined, title: 'dashboard.title' },
  { name: ResourcePathEnum.users, Icon: Icons.UserOutlined },
  { name: ResourcePathEnum.dispensers, Icon: Icons.DatabaseOutlined },
  { name: ResourcePathEnum.ads, Icon: Icons.LinkOutlined },
]

export function Menu() {
  const translate = useTranslate()
  const { selectedKey } = useMenu()
  const { mutate: logout } = useLogout()

  const identity = useGetIdentity<UserIdentity>()

  const user = identity.data?.me

  return (
    <MenuLayout>
      <RefineMenu
        className="AteMenu"
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={(event) => {
          const { key } = event
          if (key === 'logout') {
            logout()
          }
        }}
      >
        {user &&
          (user.isAdmin ? (
            <AdminMenu selectedKey={selectedKey} />
          ) : (
            <ClientMenu selectedKey={selectedKey} user={user} />
          ))}

        <RefineMenu.Item
          key="logout"
          icon={<Icons.LogoutOutlined />}
          className={classnames('AteMenuItem', '-logoutButton')}
        >
          {translate('buttons.logout')}
        </RefineMenu.Item>
      </RefineMenu>
    </MenuLayout>
  )
}

export function MenuLayout(props: PropsWithChildren<{}>) {
  const { children } = props
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      className="AteLayoutSider"
    >
      <Title collapsed={collapsed} />

      {children}
    </AntdLayout.Sider>
  )
}

interface MenuProps {
  selectedKey: string
}

function AdminMenu(props: MenuProps) {
  const { selectedKey } = props
  const translate = useTranslate()

  return (
    <>
      {menuItems.map((menuItem) => {
        const { name, Icon, title } = menuItem
        const path = `/${name}`
        const isActive = selectedKey === path
        return (
          <RefineMenu.Item
            key={path}
            icon={<Icon />}
            className={classnames('AteMenuItem', isActive ? '-active' : '')}
          >
            <Link to={path}>
              {isActive && '> '}
              {translate(title ?? `${name}.name`)}
            </Link>
          </RefineMenu.Item>
        )
      })}
    </>
  )
}

interface ClientMenuProps extends MenuProps {
  user: ExtendedUser
}

function ClientMenu(props: ClientMenuProps) {
  const { user, selectedKey } = props
  const { client } = user

  const translate = useTranslate()
  const location = useLocation()

  const dispensersIds = client?.dispensers ?? []
  const { data: dispensers, isLoading } = useMany<ExtendedDispenser>({
    resource: ResourcePathEnum.dispensers,
    ids: dispensersIds,
    queryOptions: {
      enabled: dispensersIds.length > 0,
    },
  })

  if (isLoading) return <Spin />

  return (
    <>
      <RefineMenu.Item
        key="/"
        icon={<Icons.DashboardOutlined />}
        className={classnames(
          'AteMenuItem',
          selectedKey === '/' ? '-active' : '',
        )}
      >
        <Link to="/">
          {selectedKey === '/' && '> '}
          {translate('dashboard.title')}
        </Link>
      </RefineMenu.Item>

      {dispensers?.data.map((dispenser) => {
        const { name, id } = dispenser
        const dispenserId = cleanHydraId(id)
        const path = `/${ResourcePathEnum.dispensers}/show/${dispenserId}`

        const isActive =
          dispenserId &&
          (location.pathname.includes(dispenserId) ||
            location.search.includes(dispenserId))

        return (
          <RefineMenu.Item
            key={path}
            icon={<Icons.DatabaseOutlined />}
            className={classnames('AteMenuItem', isActive ? '-active' : '')}
          >
            <Link to={path}>{name}</Link>
          </RefineMenu.Item>
        )
      })}
    </>
  )
}
