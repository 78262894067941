import type { AxiosError } from 'axios'
import axios from 'axios'
import type { User as FireBaseUser } from 'firebase/auth'
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
} from 'firebase/auth'
import jwtDecode from 'jwt-decode'
import type { PropsWithChildren } from 'react'
import { useRef, createContext, useEffect, useState } from 'react'
import usePromise from 'react-promise-suspense'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { useEnv } from 'src/adapters/Env'

import { LoginPage } from './LoginPage'
import { SignupPage } from './SignupPage'

interface DecodedApiToken {
  user_id: string
  firebaseId: string
  exp: number
}

export type User = FireBaseUser & {
  getApiToken: () => Promise<string>
  getDecodedApiToken: () => Promise<DecodedApiToken>
}

export const AuthContext = createContext<User | null>(null)

export function AuthScope(props: PropsWithChildren<{}>) {
  const { children } = props

  useSetAuthPersistence()
  const loggedUser = useLoggedUser()

  useCheckApiToken(loggedUser)

  if (loggedUser) {
    return (
      <AuthContext.Provider value={loggedUser}>{children}</AuthContext.Provider>
    )
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/signup">
          <SignupPage />
        </Route>
        <Route path="/">
          <LoginPage />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

function useSetAuthPersistence() {
  usePromise(() => {
    return getAuth().setPersistence(browserLocalPersistence)
  }, [])
}

function useCheckApiToken(loggedUser: User | null) {
  usePromise(async () => {
    if (!loggedUser) return Promise.resolve()

    try {
      return await loggedUser.getApiToken()
    } catch (error) {
      if ((error as AxiosError).response?.status === 403) {
        throw new Error('pages.error.userClientDisabled')
      }
      throw error
    }
  }, [loggedUser])
}

function useLoggedUser() {
  const env = useEnv()
  const [loggedUser, setLoggedUser] = useState<User | null>(null)
  const apiTokenRequestRef = useRef<Promise<string>>()

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, async (newUser) => {
      apiTokenRequestRef.current = undefined

      if (!newUser) return setLoggedUser(null)

      setLoggedUser(
        Object.assign(newUser, {
          async getApiToken() {
            const apiToken = await apiTokenRequestRef.current
            if (isValidToken(apiToken)) {
              return apiToken
            }

            apiTokenRequestRef.current = renewApiToken(env.API_URL, newUser)
            return await apiTokenRequestRef.current
          },
          async getDecodedApiToken() {
            return jwtDecode<DecodedApiToken>(await this.getApiToken())
          },
        }),
      )
    })

    return unsubscribe
  }, [env.API_URL])

  return loggedUser
}

interface ApiTokenResponse {
  apiToken: string
}

async function renewApiToken(apiUrl: string, user: FireBaseUser) {
  const idToken = await user?.getIdToken()
  const response = await axios.get<ApiTokenResponse>(
    `${apiUrl}/authentication`,
    { headers: { Authorization: `Bearer ${idToken}` } },
  )

  return response.data.apiToken
}

function isValidToken(token?: string): token is string {
  if (!token) return false
  const { exp } = jwtDecode<DecodedApiToken>(token)
  if (exp * 1000 < Date.now()) return false
  return true
}
