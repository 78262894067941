import type { ProfunctorState } from '@staltz/use-profunctor-state'
import L from 'leaflet'
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { useCallback, useRef, useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, useMapEvent } from 'react-leaflet'
import { useGeoCoding } from 'src/libs/useGeoCoding'
import { v4 as uuid } from 'uuid'

import 'leaflet/dist/leaflet.css'

export interface Position {
  id: string
  latitude: number
  longitude: number
  postalCode?: string
  fullAddress?: string
  city?: string
  isLoading?: boolean
  exists: boolean
}

export type PositionMap<T extends Position = Position> = Record<string, T>

interface Props {
  maxMarker?: number
  positionsStore: ProfunctorState<PositionMap>
  selectedPosition: keyof PositionMap | undefined
  setSelectedPosition: Dispatch<SetStateAction<keyof PositionMap | undefined>>
}

const myIcon = L.icon({
  iconUrl: '/Marker.png',
  iconSize: [64, 64],
  iconAnchor: [32, 48],
})

export function Map(props: Props) {
  const { selectedPosition, positionsStore, setSelectedPosition } = props
  const { state: positions } = positionsStore

  const [map, setMap] = useState<L.Map>()

  useEffect(() => {
    if (selectedPosition && map) {
      map.flyTo([
        positions[selectedPosition].latitude,
        positions[selectedPosition].longitude,
      ])
    }
  }, [selectedPosition, map, positions])

  return (
    <>
      <MapContainer
        style={{ height: '100%' }}
        center={[47.747, 7.339]}
        zoom={15}
        zoomControl={false}
        whenCreated={setMap}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkersWrapper {...props}>
          {Object.values(positions).map((position) => (
            <PositionMarker
              key={position.id}
              positionId={position.id}
              setSelectedPosition={setSelectedPosition}
              positionsStore={positionsStore}
            />
          ))}
        </MarkersWrapper>
      </MapContainer>
    </>
  )
}

function MarkersWrapper(props: PropsWithChildren<Props>) {
  const { positionsStore, maxMarker = 1, setSelectedPosition, children } = props
  const { state: positions, setState: setPositions } = positionsStore

  const { reverse } = useGeoCoding()

  useMapEvent('click', async (event) => {
    if (maxMarker === 1 && Object.values(positions).length === 1) {
      const { latlng } = event
      const { lat, lng } = latlng

      setPositions((prevPositions) => {
        const position = Object.values(prevPositions)[0]
        return { [position.id]: { ...position, latitude: lat, longitude: lng } }
      })
    }

    if (Object.values(positions).length > maxMarker - 1) return

    const { latlng } = event
    const { lat, lng } = latlng

    const newPosition: Position = {
      id: uuid(),
      latitude: lat,
      longitude: lng,
      exists: false,
      isLoading: true,
    }
    setPositions((prevPositions) => {
      return { ...prevPositions, [newPosition.id]: newPosition }
    })
    setSelectedPosition(newPosition.id)

    const res = await reverse({ lat, lon: lng })
    const adresse = res.data.features[0]?.properties
    let updatedPosition = { ...newPosition, isLoading: false }
    if (adresse) {
      updatedPosition = {
        ...updatedPosition,
        city: adresse.city,
        postalCode: adresse.postcode,
        fullAddress: adresse.label,
      }
    } else {
      updatedPosition = {
        ...updatedPosition,
        city: '',
        postalCode: '',
        fullAddress: '',
      }
    }
    setPositions((prevPositions) => {
      return { ...prevPositions, [newPosition.id]: updatedPosition }
    })
  })

  return <>{children}</>
}

interface PositionMarkerProps {
  positionId: string
  setSelectedPosition: Dispatch<SetStateAction<string | undefined>>
  positionsStore: ProfunctorState<PositionMap>
}

function PositionMarker(props: PositionMarkerProps) {
  const { positionId, setSelectedPosition, positionsStore } = props

  const { state: position, setState: setPosition } = positionsStore.promap(
    (state) => state[positionId],
    (newPosition, state) => {
      return {
        ...state,
        [positionId]: newPosition,
      }
    },
  )

  const markerRef = useRef<L.Marker>(null)
  const { reverse } = useGeoCoding()

  const dragend = useCallback(async () => {
    const marker = markerRef.current
    if (marker) {
      const { lat, lng } = marker.getLatLng()
      setPosition((prevPosition) => {
        return {
          ...prevPosition,
          latitude: lat,
          longitude: lng,
          isLoading: true,
        }
      })

      const res = await reverse({ lat, lon: lng })
      const adresse = res.data.features[0]?.properties
      if (adresse) {
        setPosition((prevPosition) => {
          return {
            ...prevPosition,
            latitude: lat,
            longitude: lng,
            city: adresse.city,
            postalCode: adresse.postcode,
            fullAddress: adresse.label,
            isLoading: false,
          }
        })
      } else {
        setPosition((prevPosition) => {
          return {
            ...prevPosition,
            latitude: lat,
            longitude: lng,
            isLoading: false,
            city: '',
            postalCode: '',
            fullAddress: '',
          }
        })
      }
    }
  }, [reverse, setPosition])

  return (
    <Marker
      key={position.id}
      ref={markerRef}
      position={[position.latitude, position.longitude]}
      icon={myIcon}
      draggable={!position.isLoading}
      eventHandlers={{
        click() {
          setSelectedPosition(undefined) // To trigger the flyTo animation even if the position was already selected
          setSelectedPosition(position.id)
        },
        dragend,
      }}
    />
  )
}
