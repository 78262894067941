import type { HydraId } from './types'

export function cleanHydraId(id: string) {
  if (isHydraId(id)) {
    return last(id.split('/'))
  }
  return id
}

export function toHydraId(resource: string, id: string): HydraId {
  if (typeof id !== 'string') return id
  if (isHydraId(id)) return id
  return `/api/${resource}/${id}`
}

function isHydraId(id?: string): id is HydraId {
  return Boolean(id && typeof id === 'string' && id.startsWith('/api/'))
}

function last<T>(array: T[]): T | undefined {
  return array[array.length - 1]
}
