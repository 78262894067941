import type { CrudFilters } from '@pankod/refine'
import type { AxiosRequestConfig } from 'axios'

const operatorsMap = {
  // supported filters
  lt: 'lt', // Less than
  gt: 'gt', // Greater than
  lte: 'lte', // Less than or equal to
  gte: 'gte', // Greater than or equal to
  null: 'exists', // Is null or not null
  eq: 'eq', // Equal

  // paramétré côté API
  contains: 'eq', // Contains
  containss: 'eq', // Contains, case sensitive

  // unsupported filters
  ne: null, // Not equal
  in: null, // Included in an array
  nin: null, // Not included in an array
  ncontains: null, // Doesn't contain
  ncontainss: null, // Doesn't contain, case sensitive
}

const dateOperatorsMap = {
  ...operatorsMap,
  lt: 'strictly_before', // Less than
  gt: 'strictly_after', // Greater than
  lte: 'before', // Less than or equal to
  gte: 'after', // Greater than or equal to
}

export function buildFilters(
  filters: CrudFilters,
): AxiosRequestConfig['params'] {
  const result: Record<string, any> = {}

  filters.forEach((filter) => {
    const { field } = filter

    if (filter.value === undefined || filter.value === '') return

    const isDate = filter.value instanceof Date
    const value = isDate ? filter.value.toISOString() : filter.value
    const operator = isDate
      ? dateOperatorsMap[filter.operator]
      : operatorsMap[filter.operator]

    if (operator === null) {
      throw Error(
        `The ${JSON.stringify(
          filter.operator,
        )} operator is not supported byt the API`,
      )
    }

    if (filter.operator.includes('contains')) {
      console.warn(
        `The ${JSON.stringify(
          filter.operator,
        )} operator will be interpreted as 'eq'. The API will determine how to do the match.`,
      )
    }

    if (operator === 'eq') {
      if (field.endsWith(']')) {
        result[field] = value
      } else {
        result[field] = result[field] ?? []
        result[field].push(...arrayify(value))
      }
    } else if (operator === 'exists') {
      result[`exists[${field}]`] = !value
    } else {
      result[`${field}[${operator}]`] = value
    }
  })

  return result
}

function arrayify(x: any): any[] {
  if (Array.isArray(x)) return x
  return [x]
}
