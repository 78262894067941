import {
  Edit,
  Typography,
  Tag,
  useMany,
  useTranslate,
  Card,
  Space,
  Image,
  useForm,
  Form,
  Input,
  usePermissions,
  RefreshButton,
  ListButton,
  CreateButton,
} from '@pankod/refine'
import { List } from 'antd'
import { Link } from 'react-router-dom'
import { cleanHydraId } from 'src/adapters/DataProvider'
import { DateField } from 'src/components/DateField'
import { FormItem } from 'src/components/FormItem'
import { NumberInput } from 'src/components/NumberInput'
import { TitleWithActions } from 'src/components/TitleWithActions'
import { ToggleEnabledButton } from 'src/components/ToggleEnabledButton'
import { TypographyLink } from 'src/components/TypographyLink'
import { useDocumentUrl } from 'src/libs/useMinioFile'
import type { Client } from 'src/types/api'
import { ResourcePathEnum } from 'src/types/api'
import { RoleEnum } from 'src/types/api/extendedTypes'
import type {
  ExtendedDispenser,
  ExtendedUser,
} from 'src/types/api/extendedTypes'

const { Title, Text } = Typography

export function EditView() {
  const translate = useTranslate()
  const { queryResult, formProps, saveButtonProps } = useForm<ExtendedUser>()
  const { data, isLoading } = queryResult ?? {}
  const user = data?.data
  const client = user?.client

  const { data: permissions } = usePermissions<RoleEnum[]>()
  const isAdmin = permissions?.includes(RoleEnum.admin) ?? false

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: '100%', alignItems: 'stretch' }}
    >
      <Edit
        isLoading={isLoading}
        saveButtonProps={saveButtonProps}
        canDelete={isAdmin}
        pageHeaderProps={{
          subTitle: (
            <>
              <Tag>
                {translate(
                  !client || client?.isActive
                    ? 'clients.values.isActive.true'
                    : 'clients.values.isActive.false',
                )}
              </Tag>
              {translate('clients.fields.createdAt')} :{' '}
              <DateField value={user?.createdAt} />
            </>
          ),
          extra: (
            <Space wrap>
              {client && isAdmin && (
                <ToggleEnabledButton<Client>
                  resource={ResourcePathEnum.clients}
                  record={client}
                  disableProp="isActive"
                  isDisabled={!client.isActive}
                />
              )}
              <ListButton />
              <RefreshButton />
            </Space>
          ),
        }}
      >
        <Form {...formProps} layout="vertical">
          <FormItem label="" name={['client', '@id']} hidden />

          <Title level={3}>{translate('users.titles.profile')}</Title>

          <FormItem
            label="users.fields.email"
            name={['email']}
            rules={[
              {
                type: 'email',
                message: translate('validation.invalidEmail'),
              },
            ]}
          >
            <Input type="email" />
          </FormItem>
          <FormItem label="users.fields.code" name={['client', 'code']}>
            <Input maxLength={50} />
          </FormItem>

          <FormItem
            label="clients.fields.description"
            name={['client', 'description']}
          >
            <Input.TextArea rows={5} />
          </FormItem>

          <Title level={3}>{translate('clients.titles.subscription')}</Title>

          <FormItem
            label="clients.fields.dispenserLimit"
            name={['client', 'dispenserLimit']}
          >
            <NumberInput min={1} />
          </FormItem>
        </Form>
      </Edit>

      <DispensersField client={client} />
    </Space>
  )
}

interface DispensersFieldProps {
  client?: Client
}

function DispensersField(props: DispensersFieldProps) {
  const translate = useTranslate()
  const { client } = props

  const dispensersIds = client?.dispensers ?? []
  const { data: dispensers, isLoading: isLoadingDispensers } =
    useMany<ExtendedDispenser>({
      resource: ResourcePathEnum.dispensers,
      ids: dispensersIds,
      queryOptions: {
        enabled: dispensersIds.length > 0,
      },
    })

  if (!client) return <Text type="danger">{translate('users.noClient')}</Text>

  return (
    <Card>
      <TitleWithActions
        title="clients.fields.dispensers"
        actions={
          client.dispenserLimit > client.dispensers.length && (
            <Link to={`/dispensers/create?client=${cleanHydraId(client.id)}`}>
              <CreateButton onClick={undefined} />
            </Link>
          )
        }
      />

      <List loading={isLoadingDispensers}>
        {dispensers?.data.map((dispenser) => (
          <DispenserItem key={dispenser.id} dispenser={dispenser} />
        ))}
      </List>
    </Card>
  )
}

interface DispenserItemProps {
  dispenser: ExtendedDispenser
}
function DispenserItem(props: DispenserItemProps) {
  const { dispenser } = props
  const imageUrl = useDocumentUrl(dispenser.documents[0].id)

  return (
    <List.Item>
      <List.Item.Meta
        avatar={<Image src={imageUrl} width={64} height={64} />}
        title={
          <TypographyLink
            to={`/${ResourcePathEnum.dispensers}/show/${cleanHydraId(
              dispenser.id,
            )}`}
          >
            {dispenser.name}
          </TypographyLink>
        }
        description={<Text ellipsis>{dispenser.description}</Text>}
      />
    </List.Item>
  )
}
